<template>
  <div class="body"  v-loading="pageLoading">
    <div  v-if="!isBind"  style="text-align: center;">
      <template>
        <div class="home" style="margin:50px auto;">
          <wx-open-launch-weapp id="launch-btn" appId="wx790f38fb29e7fce8"
                                :path="'pages/ldy/ldy.html?type=ldy&openid='+openid"
                                @launch="handleLaunchFn" @error="handleErrorFn">
            <script type="text/wxtag-template">
              <style>
                .btn { padding: 12px;width: 300px;border-radius: 5px;background-color:#2F89DC;border: none;outline: none;color: #FFFFFF; }
              </style>
              <button class="btn">授权获取手机号</button>
            </script>
          </wx-open-launch-weapp>
        </div>
      </template>
    </div>
    <div  v-else>
      <div class="tip-content">
        <span style="text-align: center;">账户信息</span>
      </div>
      <div class="loginContainerLook">
        <div class="uni-input-wrapper-look margintop">
          <span style="font-size:13px;white-space: pre-wrap;">姓 名：</span><span>{{nameLook}}</span>
        </div>
      </div>
      <div class="loginContainerLook">
        <div class="uni-input-wrapper-look margintop">
          <span style="font-size:13px;white-space: pre-wrap;">手机号：</span><span>{{phoneLook}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as Api from './api.js';
import CryptoJS from "crypto-js";
import wx from 'weixin-js-sdk'
export default {
  name: "eoc",
  data() {
    return {
      isBind: false, // 是否绑定
      nameLook: '', // 查看的姓名
      phoneLook: '', // 查看的手机号
      url:location.href,
      code:'',
      openid:'',
      pageLoading:false,
      timerId:null,
    };
  },
  mounted() {
    document.title = '链动云';
    this.disposeUrl();
  },
  methods: {
    getIsBindFuc(){
      let param = {
        openid: this.openid,
        fwh: 'ldy',
      }
      const callmethod = "msg010011";
      Api.startReq(param, callmethod, res => {
        if (res.msgcode == 1) {
          if (!!res.resultdata) { //类型：Object  必有字段  备注：未绑定返回NULL，已绑定返回绑定信息
            this.isBind = true;
            this.nameLook = res.resultdata.zsxm;
            this.phoneLook = res.resultdata.tel;
            this.stopTimer();
          } else {
            this.isBind = false;
          }
        }
      }, fail => {})
    },
    disposeUrl(){
      if(this.url.split('?')[1]){
        const paramsArray = this.url.split('?')[1].split('&')
        const params = {};
        paramsArray.forEach(param => {
          const [key, value] = param.split('='); // 分割出参数名和参数值
          params[key] = decodeURIComponent(value); // 将参数键值对存入params对象
        });
        this.code = params.code
        this.getOpenID();
      }else{
        this.$message({
          type: "error",
          message: "网络错误!",
        });
      }
    },
    handleLaunchFn: function(e) {
      console.log("模板成功", e)
    },
    stopTimer() {
      clearInterval(this.timerId); // 清除定时器
    },
    handleErrorFn: function(e) {
      console.log("模板报错", e)
    },
    getJSHttp() {
      let param = {
        url: location.href.split('#')[0],
        ly: "ldy"
      }
      let callmethod = 'msg010014';
      Api.startReq(param, callmethod, res => {
        if (res.msgcode == 1) {
          const {
            appId,
            nonceStr,
            signature,
            timestamp
          } = res.resultdata;
          this.jsConfig(appId, timestamp, nonceStr, signature);
        }
      }, fail => {})
   },
    jsConfig(appId, timestamp, nonceStr, signature) {
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        appId: appId, // 必填，公众号的唯一标识
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: nonceStr, // 必填，生成签名的随机串
        signature: signature, // 必填，签名
        jsApiList: [
          'scanQRCode',
        ], // 必填，需要使用的JS接口列表
        openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
      });
      this.pageLoading = false;
      this.timerId = setInterval(() => {
        this.getIsBindFuc();
      }, 10000);
    },
    getOpenID(){
      this.pageLoading = true;
      let param = {
        code:this.code,
      }
      const callmethod = "msg020002";
      Api.startReq(param, callmethod, res => {
        if (res.msgcode == 1) {
          this.openid = res.resultdata
          this.isBindFuc();
        }
      }, fail => {})
    },
    // 获取是否绑定
    isBindFuc() {
      let param = {
        openid: this.openid,
        fwh: 'ldy',
      }
      const callmethod = "msg010011";
      Api.startReq(param, callmethod, res => {
        if (res.msgcode == 1) {
            if (!!res.resultdata) { //类型：Object  必有字段  备注：未绑定返回NULL，已绑定返回绑定信息
              this.isBind = true;
              this.pageLoading = false;
              this.nameLook = res.resultdata.zsxm;
              this.phoneLook = res.resultdata.tel;
            } else {
              this.isBind = false;
              this.getJSHttp();
            }
        }
      }, fail => {})
    },
  },
};
</script>

<style scoped>
 .body{
   width:100%;
   min-width: 100%!important;
   overflow: hidden;
 }
 .uni-input-wrapper-look {
   width:100%;
   display: flex;
   line-height: 28px;
   height: 28px;
   flex-direction: row;
   flex-wrap: nowrap;
   background-color: #FFFFFF;
 }
 .tip-content {
   width:100%;
   background-color: #4FA6F7;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding: 7px 15px;
 }

 .tip-content span {
   font-size: 15px;
   color: #FFFFFF;
 }
</style>
