
import { EncryptCaller } from "./encryption"
import request from "@/plugin/axios"
const getEventid = () => Math.floor(Math.random() * (99999999 - 10000000) + 10000000);
import CryptoJS from "crypto-js";

const appInfo = {
	_appname: "DevAssistant",
	_appentrance: "DevAssistant_Win",
	_appversion: "v2.22"
}
// const url = 'https://gtw.ddmg.com/api/basecall';
const url = 'https://lyp.ddmg.com/api/BaseCall';
// const url = 'http://192.168.199.240:32552/api/basecall';
const timestamp = new Date().getTime();

export const startReq = (param,callmethod,success,fail)=>{
	const header = {
		callmethod,
		hostid: '8000',
		caller: '2HRvZRNwmKzS',
	}
	const reqBody = {
		timestamp,
		callmethod,
		version: 1,
		isdebug: 1,
		data: param,
		hostid: '8000',
		clientid: 'eoc',
	};
	let data = {
		...appInfo,
		_host: '8000',
		_caller: '2HRvZRNwmKzS',
		_callmethod: callmethod,
		_timestamp: timestamp,
		_eventid: getEventid(),
		_loginkey: '',
		_data: EncryptCaller(reqBody),
	};
	request({
		url: url,
		method: "post",
		headers: header,
		data: data,
	}).then((result) => {
		success(result)
	}).catch((e) => {
		fail(e)
	});
}